
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import FilteredPatients from "../components/tdm/FilteredPatients.vue";
import PatientInfo from "../components/tdm/PatientInfo.vue";
import CreatineTest from "../components/tdm/CreatineTest.vue";
import Physic from "../components/tdm/Physic.vue";
import TdmTest from "../components/tdm/TdmTest.vue";
import TdmRes from "../components/tdm/TdmRes.vue";
import { usePatientsStore } from '@/store/patients';
import { useTdmStore } from '@/store/tdm';
import { usePopupStore } from '@/store/popup'
import DatepickerPopup from '@/components/popup/DatepickerPopup.vue';

export default defineComponent({
  components: {
    FilteredPatients,
    PatientInfo,
    CreatineTest,
    Physic,
    TdmTest,
    TdmRes,
    DatepickerPopup
  },
  props:{
  },
  setup(props, {emit}) {
    const popupStore = usePopupStore();
    const openDatepicker = computed(() => popupStore.getIsOpen('datepicker'));

    const currPatient = computed(() => usePatientsStore().getCurrPatient);
    onMounted(() => {
      useTdmStore().setCurrTab("predict");
    });

    return {
      currPatient,
      openDatepicker
    };
  },
});
