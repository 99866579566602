
import { defineComponent, ref, onMounted, nextTick, computed, watch } from 'vue';
import { useMonitoringStore } from '@/store/monitoring'

export default defineComponent({
  components: { },
  setup() {
    const monitoringStore = useMonitoringStore();
    const chartData = computed(() => monitoringStore.getTrackChartData);

    watch(() => chartData.value, (newVal) => {
    });

    onMounted(async () => {
    });

    return { 
      chartData
    };
  },
});
