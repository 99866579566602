
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import FilteredPatients from "../components/monitoring/FilteredPatients.vue";
import PatientInfo from "../components/monitoring/PatientInfo.vue";
import MonitoringChart from "../components/monitoring/MonitoringChart.vue";
import MonitoringData from "../components/monitoring/MonitoringData.vue";
import MonitoringTrackData from "../components/monitoring/MonitoringTrackData.vue";
import { usePatientsStore } from '@/store/patients';
import { useTdmStore } from '@/store/tdm';
import { useMonitoringStore } from '@/store/monitoring';

export default defineComponent({
  components: {
    FilteredPatients,
    PatientInfo,
    MonitoringChart,
    MonitoringData,
    MonitoringTrackData
  },
  props:{
  },
  setup(props, {emit}) {
    const monitoringStore = useMonitoringStore();
    const currPatient = computed(() => usePatientsStore().getCurrPatient);
    const currTab = computed(() => monitoringStore.getCurrTab);

    onMounted(() => {
      useTdmStore().setCurrTab("predict");
    });

    return {
      currPatient,
      currTab
    };
  },
});
