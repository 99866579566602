import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c6ce9f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datepicker_wrap" }
const _hoisted_2 = { class: "datepicker_box" }
const _hoisted_3 = { class: "datepicker_btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DatePicker, {
        modelValue: _ctx.selectedDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
        class: "datepicker",
        mode: "dateTime",
        "minute-increment": 60
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "button__blue",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleConfirmClick()))
        }, "적용"),
        _createElementVNode("button", {
          class: "button__blue",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDatepicker()))
        }, "취소")
      ])
    ])
  ]))
}