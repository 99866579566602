import { defineStore } from 'pinia';
import { readPatientInfo, readPuInfo, readPuThreshold, updatePuThreshold, resetPuThreshold, readTrackPatientInfo, readTrackData, readTrackThreshold, updateTrackThreshold, resetTrackThreshold } from '../api';
import { useIcuStore, ICU } from '@/store/icu';

interface Patient {
  STAY_ID: string;
  TRACK_DATE: string;
  TRACK_INFO: any;
  TRACK_PREDICTION: number;
  PT_NO: string;
  PT_NM: string;
  WD_DEPT_CD: string;
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  PREDICTION: number;
  PU_INFO: any;
}

export const useMonitoringStore = defineStore('monitoring', {
  state: () => {
    const initData: Patient = {
      STAY_ID: '',
      TRACK_DATE: '',
      TRACK_INFO: [],
      TRACK_PREDICTION: 0,
      PT_NO: '',
      PT_NM: '',
      WD_DEPT_CD: '',
      AGE: 0,
      HEIGHT: 0,
      WEIGHT: 0,
      SEX_TP_CD: '',
      PREDICTION: 0,
      PU_INFO: []
    };

    return {
      initData,
      patients: [] as Patient[],
      filteredPatients: [] as Patient[],
      currPatient: { ...initData },
      editPatient: { ...initData },
      chartData: [] as any[],
      trackChartData: [] as any[],
      currTab: 'pu',
      puThreshold: 0,
      trackThreshold: 0
    };
  },
  actions: {
    async getPatientData(): Promise<void> {
      try {
        type PatientWithoutID = Omit<Patient, 'STAY_ID' | 'TRACK_DATE' | 'TRACK_INFO' | 'TRACK_PREDICTION'>;
        const res = await readPatientInfo() as PatientWithoutID[];
        this.patients = res.map(patient => ({
          STAY_ID: '',
          TRACK_DATE: '',
          TRACK_INFO: [],
          TRACK_PREDICTION: 0,
          ...patient,
          PU_INFO: [] // PU_INFO 기본값 설정
        }));
        await this.getPuInfo();

        // 필터링된 환자 목록 설정
        this.setFilteredPatients();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    },
    async getPuInfo() {
      try {
        for(let i=0; i<this.patients.length; i++) {
          const res = await readPuInfo(this.patients[i].PT_NO);
          this.patients[i].PU_INFO = res.data;
        }
      } catch (error) {
        console.error('Error fetching:', error);
      } 
    },
    setFilteredPatients() {
      try {
        this.filteredPatients = [];
        const icuStore = useIcuStore();
        const icuCodes = icuStore.getIcuList
          .filter((icu: ICU) => icu.checked)
          .map((icu: ICU) => icu.icu);

        this.filteredPatients = this.patients.filter(patient => 
          icuCodes.includes(patient.WD_DEPT_CD)
        );
      } catch (error) {
        console.error('Error filtering patients:', error);
      }
    },
    setCurrPatient(patient: Patient) {
      this.currPatient = { ...patient };
    },
    setChartData(data: any) {
      this.chartData = data;
    },
    setCurrTab(tab: string) {
      this.currTab = tab;
    },
    async readPuThreshold(): Promise<void> {
      try {
        const res = await readPuThreshold();
        this.puThreshold = res;
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async updatePuThreshold(threshold: number): Promise<void> {
      try {
        await updatePuThreshold(threshold);
        await this.readPuThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async resetPuThreshold(): Promise<void> {
      try {
        await resetPuThreshold();
        await this.readPuThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async getTrackPatientData(): Promise<void> {
      try {
        const res = await readTrackPatientInfo();
        for(let i=0; i<res.length; i++) {
          const patientToUpdate = this.patients.find(patient => patient.PT_NO == res[i].pt_no);
          if (patientToUpdate) {
            patientToUpdate.STAY_ID = res[i].stay_id;
            patientToUpdate.TRACK_DATE = res[i].track_date;
            patientToUpdate.TRACK_PREDICTION = res[i].prediction;
          }
        }
        await this.readTrackData();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    },
    async readTrackData() {
      try {
        for (let i = 0; i < this.patients.length; i++) {
          const res = await readTrackData(this.patients[i].STAY_ID);
          this.patients[i].TRACK_INFO = res.data;
        }
        // console.log('track res >> ', this.patients);
      } catch (error) {
        console.error('Error fetching:', error);
      }
    },
    async readTrackThreshold(): Promise<void> {
      try {
        const res = await readTrackThreshold();
        this.trackThreshold = res;
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async updateTrackThreshold(threshold: number): Promise<void> {
      try {
        await updateTrackThreshold(threshold);
        await this.readTrackThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async resetTrackThreshold(): Promise<void> {
      try {
        await resetTrackThreshold();
        await this.readTrackThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    },
    setTrackChartData(data: any) {
      this.trackChartData = data;
    },
  },
  getters: {
    getPatients(state): Patient[] {
      return state.patients;
    },
    getFilteredPatients(state): Patient[] {
      return state.filteredPatients;
    },
    getCurrPatient(state): Patient {
      return state.currPatient;
    },
    getChartData(state): any[] {
      return state.chartData;
    },
    getTrackChartData(state): any[] {
      return state.trackChartData;
    },
    getCurrTab(state): any {
      return state.currTab;
    },
    getPuThreshold(state): any {
      return state.puThreshold;
    },
    getTrackThreshold(state): any {
      return state.trackThreshold;
    }
  },
});
