import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abdf381e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view monitoringinfo" }
const _hoisted_2 = { class: "info-box" }
const _hoisted_3 = { class: "monitoring_box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilteredPatients = _resolveComponent("FilteredPatients")!
  const _component_PatientInfo = _resolveComponent("PatientInfo")!
  const _component_MonitoringChart = _resolveComponent("MonitoringChart")!
  const _component_MonitoringData = _resolveComponent("MonitoringData")!
  const _component_MonitoringTrackData = _resolveComponent("MonitoringTrackData")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilteredPatients),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PatientInfo),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MonitoringChart),
        (_ctx.currTab=='pu')
          ? (_openBlock(), _createBlock(_component_MonitoringData, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.currTab=='track')
          ? (_openBlock(), _createBlock(_component_MonitoringTrackData, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}