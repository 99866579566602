
import { defineComponent, ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { usePopupStore } from '@/store/popup'
import { useDatePickerStore } from '@/store/datepicker'
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

export default defineComponent({
  components: {
    DatePicker
  },
  props:{

  },
  setup(props, {emit}) {
    const popupStore = usePopupStore();
    const datePickerStore = useDatePickerStore();
    const open = computed(() => popupStore.getIsOpen('datepicker'));
    const targetDate = computed(() => datePickerStore.getDate);
    const selectedDate = ref();

    const handleConfirmClick = () => {
      console.log('targetDate >> ', selectedDate.value);
      datePickerStore.setDate(selectedDate.value);
      popupStore.togglePopup('datepicker', false);
    }

    const closeDatepicker = () => {
      popupStore.togglePopup('datepicker', false);
    }

    onMounted(() => {
      selectedDate.value = targetDate.value;
    });

    onUnmounted(() => {
    });

    return {
      closeDatepicker,
      targetDate,
      selectedDate,
      handleConfirmClick
    };
  },
});
