
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import Nav from "../components/Nav.vue";
import EditUserInfo from "../components/EditUserInfo.vue";
import PredictPopup from "@/components/popup/PredictPopup.vue";
import PredictAlertPopup from "@/components/popup/PredictAlertPopup.vue";
import LogoutAlertPopup from "../components/popup/LogoutAlertPopup.vue";
import { useUsersStore } from '@/store/users'
import { usePatientsStore } from '@/store/patients'
import { usePopupStore } from '@/store/popup'
import { useMonitoringStore } from '@/store/monitoring'

export default defineComponent({
  components: {
    Nav,
    EditUserInfo,
    PredictPopup,
    LogoutAlertPopup,
    PredictAlertPopup
  },
  props:{
  },
  setup(props, {emit}) {
    const popupStore = usePopupStore();
    const showEditUserInfoPage = computed(() => useUsersStore().getShowEditUserInfoPage);
    const predictErrorPopup = computed(() => popupStore.getIsOpen('tdmPrError'));
    const predictAlertPopup = computed(() => popupStore.getIsOpen('tdmPrAlert'));
    const logoutAlertPopupIsOpen = computed(() => popupStore.getIsOpen('logoutAlert'));
    
    const getPatientsData = async (): Promise<void> => {
     await usePatientsStore().getPatientData();
     await useMonitoringStore().getPatientData();
     await useMonitoringStore().getTrackPatientData();
    }

    onMounted(() => {
      getPatientsData();
      popupStore.initPopupState();
    });

    return {
      showEditUserInfoPage,
      predictErrorPopup,
      predictAlertPopup,
      logoutAlertPopupIsOpen
    };
  },
});
