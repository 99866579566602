import { defineStore } from "pinia";

export const useDatePickerStore = defineStore('datepicker', {
  state: () => ({
    date: new Date(),
  }),
  actions: {
    setDate(actTime: any) {
      this.date = actTime;
    },
  },
  getters: {
    getDate(state) {
      return state.date;
    },
  },
});